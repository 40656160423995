import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'


const Talks = () => {

  const talks = useStaticQuery(graphql`
  query {
    allContentfulTalksAndTeaching(
      filter: { talk: { eq: true }}
    ){
      edges {
        node{
          titleText
          id
          descriptionOfTalk {
            json
          }
          image {
            title
            file{
              url
            }
          }
        }
      }
    }
  }
`)

  const talkItem = talks.allContentfulTalksAndTeaching.edges.map(talk => {
    return (
      <div className="flex flex-wrap mb-8" key={talk.node.id}>
        <div className="sm:w-full md:w-3/12 lg:w-1/6 m-auto">
          <div className="px-2 rounded-full flex items-center justify-center border-2 border-gray-900 h-24 w-24">
            <img alt={talk.node.image.title} className="object-scale-down p-1" src={talk.node.image.file.url} />
          </div>
        </div>
        <div className="sm:w-full md:w-9/12 lg:w-5/6 mt-8 md:mt-0">
          <p className="font-bold">{talk.node.titleText}</p>
          {documentToReactComponents(talk.node.descriptionOfTalk.json)}
        </div>
      </div>
    )
  })

  return (
    <section className="mb-20" id="all-thoughts">
      <h3 className="text-3xl font-bold mb-8">Talks</h3>
      {talkItem}
    </section>
  )
}

export default Talks
