import React from 'react'

import BreadCrumb from '../components/breadCrumb/index'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ThoughtsOn from '../components/thoughtsOn/index'
import Talks from '../components/talks/talks'
import Teaching from '../components/teaching/teaching'


const crumbs = [
  {
    depth: '1',
    url: '/',
    title: 'Home',
    styles: '',
  },
]

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        keywords={[`UX`, `Freelance`, `Designer`, `Researcher`]}
        title="About"
      />
      <BreadCrumb crumbs={crumbs} />
      <section className="flex flex-col md:flex-row items-center">
        <section id="Talks and Teaching" className="mb-8">
          <h2>Talks &amp; teaching</h2>
          <p className="text-2xl mb-6">
            I am incredibly passionate about sharing knowledge, whether that is within other areas of the business I work, at industry events or educational institutes. 
          </p>
          <p className="mb-5">
            I've done a number of talks about UX, design thinking, but also about leadership and wellbeing. I've also taught design methods to college and university students and am currently a lead tutor as part of the Innovater Her organisation, teaching and inspiring girls at high school.
          </p>
        </section>
      </section>
      <hr className="mb-12 mt-4" />
      <Talks />
      <hr className="mb-8 mt-12" />
      <Teaching />
      <hr className="mb-8 mt-12" />

      <ThoughtsOn />
    </Layout>
  )
}

export default AboutPage
